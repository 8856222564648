import Register from "./register.vue";
import { setCookie, getCookie, removeCookie } from "../../utils/auth";
import { goLogin } from '@/api';
export default {
  // "15888888888"  "123qwe"
  components: {
    Register
  },
  mounted() {
    // console.log(this.$router.currentRoute.value.query.redirect)

    // console.log(getCookie("account"))
    // this.$store.dispatch('getUserInfo')

    if (this.$route.query.uid) {
      this.selIndex = 1;
    }
  },
  data() {
    return {
      selIndex: 0,
      remember: true,
      account: getCookie("account") || "",
      password: getCookie("password") || ""
    };
  },
  methods: {
    goBack() {
      this.$router.replace("/");
      //   $router.go(-1)
    },
    login() {
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      //   axios.post({ url: "http://45.207.9.192/Mobile/login", data: { account: this.account, password: this.password } }, headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }).then(res => {
      //     console.log(res)
      //   })

      goLogin({
        account: this.account,
        password: this.password
      }).then(res => {
        this.$closeToast();
        setCookie('token', res.data.token);
        const {
          redirect
        } = this.$route.query;

        // 记住密码
        if (this.remember) {
          setCookie('account', this.account);
          setCookie('password', this.password);
        } else {
          removeCookie('account');
          removeCookie('password');
        }
        this.$store.dispatch('getUserInfo').then(() => {
          if (redirect) {
            this.$router.replace(redirect);
          } else {
            this.$router.replace("/");
          }
        });
      });
    }
  }
};